import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import React from 'react';
import { StyledOption, Select } from '../RegionSelect/CustomSelect';
import { BackDrop, boxStyle, header, mainBox, Hint } from '../RegionSelect/regionStyles';
import PropTypes from 'prop-types';
import { useSelector, mediaMobile } from '@worklist-2/core/src';
import { useTranslation } from 'react-i18next';

const LanguageModalContent = ({ onChange }) => {
	const { languages, language, updateValue } = useSelector();
	const { i18n } = useTranslation();
	const { t } = useTranslation('login');

	const onLanguageChange = language => {
		i18n.changeLanguage(language);
		updateValue('language', language);
		onChange();
	};
	return (
		<Box sx={boxStyle}>
			<Box sx={header}>{t('Choose Your Language')}</Box>
			<Paper
				sx={{
					p: '2px 4px',
					display: 'flex',
					alignItems: 'center',
					background: '#FFF',
					minWidth: '500px',
					border: '1px solid',
					'&:hover': {
						borderColor: '#42A5F5',
					},
					[mediaMobile]: {
						width: '100%',
						minWidth: 'unset',
						boxSizing: 'border-box',
						'.MuiSelectUnstyled-popper':{
							width:'100%',
							display:'flex',
							justifyContent:'center'
						}
					},
				}}
			>
				<div style={{ width: '100%' }}>
					<Hint>{t('CURRENTLY SELECTED')}</Hint>
					<Select value={language} onChange={v => onLanguageChange(v)}>
						{languages.map(c => (
							<StyledOption key={c.code} value={c.code}>
								<div className="language">{c.name}</div>
							</StyledOption>
						))}
					</Select>
				</div>
			</Paper>
		</Box>
	);
};

const LanguageModal = ({ open, onClose }) => (
	<Modal
		BackdropComponent={() => (
			<BackDrop>
				<Box sx={mainBox}>
					<IconButton sx={{ position: 'absolute', top: 10, right: 20 }} onClick={onClose}>
						<CloseIcon sx={{ color: '#000' }} />
					</IconButton>
					<LanguageModalContent
						onChange={() => {
							onClose();
						}}
					/>
				</Box>
			</BackDrop>
		)}
		open={open}
		onClose={onClose}
	>
		<></>
	</Modal>
);

export default LanguageModal;
export { LanguageModalContent };

LanguageModal.propTypes = {
	open: PropTypes.func.isRequired,
	close: PropTypes.func.isRequired,
};
