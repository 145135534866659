import React from 'react';
import Box from '@mui/material/Box';
import { LanguageModalContent } from '../../LanguageSelect/LanguageModal';

const MobileLanguageDrawer = ({setMobileDrawer}) => {
	const handleClose = () => {
        if (setMobileDrawer) {
            setMobileDrawer(false); // Call the function to auto-close the menu
        }
    };
	return(
	<Box>
		<LanguageModalContent onChange={handleClose}/>
	</Box>
	);
};

export default MobileLanguageDrawer;
